import { Container } from '@components/ui'
import config from '@config/seo_meta.json'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import Image from 'next/legacy/image'
interface Props {
  slice: any
}

const HomepageTrustBar = ({ slice }: Props) => {
  const sliceItems = slice?.items
  const trustBarText = slice?.primary?.TrustBarText
  const [ref] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      '(min-width: 768px)': {
        slides: { perView: 3.5, spacing: 48 },
      },
    },
    slides: {
      perView: 2.8,
      spacing: 48,
    },
  })
  return (
    <section>
      <div className="w-full bg-brand-white pt-11 pb-14 lg:pt-12 lg:pb-16">
        <Container>
          <div className="label mb-10 flex justify-center text-brand-dark-grey">
            {trustBarText}
          </div>
          <div className="hidden items-center justify-center gap-4 lg:flex lg:flex-row lg:gap-10">
            {sliceItems.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item?.TrustBarImage?.url && (
                    <Image
                      src={item.TrustBarImage.url}
                      alt={
                        item?.TrustBarImage?.alt ||
                        config.openGraph.images[0].alt
                      }
                      width={item.TrustBarImage.dimensions.width}
                      height={item.TrustBarImage.dimensions.height}
                      objectFit="contain"
                    />
                  )}
                </div>
              )
            })}
          </div>
          <div className="visible -mr-4 lg:hidden">
            <div ref={ref} className="keen-slider">
              {sliceItems.map((item: any, index: number) => {
                return (
                  <div key={index} className="keen-slider__slide">
                    {item?.TrustBarImage?.url && (
                      <Image
                        src={item.TrustBarImage.url}
                        alt={
                          item?.TrustBarImage?.alt ||
                          config.openGraph.images[0].alt
                        }
                        width={item.TrustBarImage.dimensions.width}
                        height={item.TrustBarImage.dimensions.height}
                        objectFit="contain"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </Container>
      </div>
    </section>
  )
}

export default HomepageTrustBar
